import { defineComponent } from '@vue/composition-api'
import Command from '@/libs/Command'
import { MainListLayout } from '@/layouts'
import { __sync } from 'babel-plugin-vue-jsx-modifier/lib/modifiers'
import styled from 'vue-styled-components'
import { EntityQuery } from '@/components/containers'
import BEmptyState from '../../../components/common/BEmptyState'

const Divider = styled.div`
    margin-bottom: 16px;
    border-bottom: 1px solid #ccc;
`
const Table = defineComponent({
    name: 'Table',
    props: {
        page: {
            type: Number,
            default: 1,
        },
        pageSize: {
            type: Number,
            default: 10,
        },
        sortColumn: {
            type: String,
            default: 'Name',
        },
        sortDirection: {
            type: String,
            default: 'asc',
        },
        items: {
            type: Array,
            default: () => [],
        },
        selectedItems: {
            type: Array,
            default: () => [],
        },
        totalItems: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            checkedRows: [],
        }
    },
    watch: {
        items: {
            deep: true,
            handler() {
                this.checkedRows = []
                this.$emit('update:selectedItems', this.checkedRows)
            },
        },
        checkedRows: {
            deep: true,
            handler() {
                this.$emit('update:selectedItems', this.checkedRows)
            },
        },
    },
    render() {
        const Paginator = ({ props: { page, pageSize, totalItems } }) => (
            <LayoutFlexRow class='layout-u-my-5'>
                <div slot='remain'>
                    <BPagination
                        total={totalItems}
                        per-page={pageSize}
                        current={page}
                        v-on:change={(value) => {
                            page = value
                        }}
                    />
                </div>
            </LayoutFlexRow>
        )
        return (
            <div>
                <div class='card card-container'>
                    <BTable
                        data={this.items}
                        backend-pagination
                        backend-sorting
                        checkable
                        current-page={this.page}
                        per-page={this.pageSize}
                        checked-rows={__sync(this.checkedRows)}
                        default-sort-direction={this.sortDirection}
                        default-sort={this.sortColumn}
                        v-on:sort={(field, order) => {
                            console.log(`[${this.tag}]sort: `, field, order)
                            // this.sortColumn = field
                            this.$emit('update:sortColumn', field)
                            // this.sortDirection = order
                            this.$emit('update:sortDirection', order)
                        }}>
                        {/* <ColumnName />
                    <ColumnDescription />
                    <ColumnUserCount />
                    <ColumnControl /> */}
                        {this.$scopedSlots.default && this.$scopedSlots.default()}
                    </BTable>
                </div>
                <Paginator page={this.page} pageSize={this.pageSize} totalItems={this.totalItems} />
            </div>
        )
    },
})

export default defineComponent({
    name: 'List',
    props: {},
    url: {
        __root: 'params',

        keyword: { key: 'kw' },
        page: { key: 'page' },
        pageSize: { key: 'size' },
        sortColumn: { key: 'col' },
        sortDirection: { key: 'dir' },
    },
    data() {
        const params = {
            keyword: '',
            page: 1,
            pageSize: 10,
            sortColumn: 'Name',
            sortDirection: 'asc',
        }
        return {
            selectedRows: [],
            actionPermission: {
                resources: 'Role',
                any: true,
                actions: ['Delete'],
            },
            actionItems: [
                {
                    label: 'Delete',
                    do: () => this.confirmDelete(this.selectedRows),
                    permission: { resources: 'Role', actions: ['Delete'] },
                },
            ],

            params,

            deleteRoleCommand: new Command(this.$http, 'DELETE', 'RolesRemove'),
        }
    },
    watch: {},
    methods: {
        confirmDelete(selectedRow) {
            let formattedNames = selectedRow.map((p) => `<b>${p.Name}</b>`).join('<br/>')
            let nPos = selectedRow.length > 1 ? selectedRow.length : ''
            let confirmMsg = `Are you sure you want to delete the following ${nPos} role?<br/><br/>`
            let rolesId = selectedRow.map((p) => p.Id)

            this.$buefy.dialog.confirm({
                title: 'Confirm',
                message: confirmMsg + formattedNames,
                confirmText: 'Delete',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    this.deleteRoleCommand.execute({ Ids: rolesId }).then((resp) => {
                        if (resp.status === 204) {
                            this.rolesQuery.execute(this.params)
                            this.selectedRows = []
                        }
                    })
                },
            })
        },
    },
    render() {
        const BLoadingState = () => (
            <div class='is-relative' style={{ minHeight: '300px' }}>
                {/* <span class='icon is-medium'>
                    <i class='fas fa-lg fa-spinner fa-pulse' />
                </span> */}
                <BLoading isFullPage={false} active />
            </div>
        )

        const BErrorState = ({ props: { border, title, subtitle } }) => (
            <LayoutFlexColumn
                padding='10'
                gap='5'
                horizontalAlign='center'
                style={border && { border: '1px solid #ddd' }}>
                {/* <img slot='remain' width='250px' src={EmptyImg} /> */}
                <span slot='remain' class='icon is-large has-text-danger'>
                    <i class='mdi mdi-48px mdi-alert-circle' />
                </span>
                <div slot='bottom' class='has-text-grey has-text-centered'>
                    <h1 class='title is-4'>{title || 'Something went wrong!'}</h1>
                    <h2 class='subtitle is-6'>{subtitle || 'Please try again later or contact your administrator.'}</h2>
                </div>
            </LayoutFlexColumn>
        )

        const Header = () => (
            <LayoutFlexRow class='layout-u-mb-5' vertical-align='bottom'>
                <div slot='left'>
                    <span class='title'>Role</span>
                </div>
                <div slot='right'>
                    {this.selectedRows.length > 0 ? (
                        <BActionButton
                            actionPermission={this.actionPermission}
                            actionItems={this.actionItems}
                            selectedRows={this.selectedRows}
                        />
                    ) : (
                        <button
                            onClick={() => this.$router.push({ name: 'roleCreate' })}
                            class='button is-primary ml-16 px-5'>
                            Create
                        </button>
                    )}
                </div>
            </LayoutFlexRow>
        )

        const Filters = () => (
            <LayoutFlexRow class='layout-u-mb-5' vertical-align='bottom'>
                <div slot='left'>
                    <BSearchBox
                        ref='searchBox'
                        initValue={this.params.keyword}
                        placeholder='Search Role Name, Description...'
                        v-on:submit={(value) => (this.params.keyword = value)}
                    />
                </div>
                <div slot='right'>
                    <BPageSize v-model={this.params.pageSize} options={[10, 20, 50, 100, 500, 1000]} />
                </div>
            </LayoutFlexRow>
        )

        const Counter = ({ props: { loading, beginItem, endItem, totalItems } }) => (
            <LayoutFlexRow class='layout-u-mb-0' vertical-align='bottom'>
                <div slot='right'>
                    <BPagingCounter loading={loading} beginRow={beginItem} endRow={endItem} totalRows={totalItems} />
                </div>
            </LayoutFlexRow>
        )

        const ColumnName = () => (
            <b-table-column
                field='Name'
                label='Name'
                sortable
                scopedSlots={{
                    default: ({ row }) => (
                        <router-link to={{ name: 'roleDetail', params: { roleId: row.id, tabId: 0 } }} exact>
                            <span class='item-link'>{row.name}</span>
                        </router-link>
                    ),
                }}
            />
        )

        const ColumnDescription = () => (
            <b-table-column
                field='Description'
                label='Description'
                scopedSlots={{
                    default: ({ row }) => <span>{row.description}</span>,
                }}
            />
        )

        const ColumnUserCount = () => (
            <b-table-column
                field='Users'
                label='No. of User'
                scopedSlots={{
                    default: ({ row }) => <span>{row.users.length}</span>,
                }}
            />
        )

        const ColumnControl = () => (
            <b-table-column
                field='control'
                centered
                scopedSlots={{
                    default: ({ row }) => (
                        <b-dropdown position='is-bottom-left'>
                            <button class='button border-0 btn-control' slot='trigger'>
                                <b-icon icon='dots-horizontal' size='is-small'></b-icon>
                            </button>
                            <b-dropdown-item has-link v-permit={{ resources: 'Role', actions: ['Edit'] }}>
                                <router-link to={{ name: 'roleEdit', params: { roleId: row.id } }} exact>
                                    Edit
                                </router-link>
                            </b-dropdown-item>
                            <b-dropdown-item
                                onClick={() => this.confirmDelete([row])}
                                v-permit={{ resources: 'Role', actions: ['Delete'] }}>
                                Delete
                            </b-dropdown-item>
                        </b-dropdown>
                    ),
                }}
            />
        )

        const querySlots = {
            // controls: ({ load }) => <button onClick={() => load()}>Query</button>,
            loading: () => <BLoadingState />,
            error: ({ error }) => <BErrorState />,
            empty: () => <BEmptyState />,
            default: ({ loading, items, beginItem, endItem, totalItems }) => (
                <div>
                    <Counter loading={loading} beginItem={beginItem} endItem={endItem} totalItems={totalItems} />
                    <Table
                        page={this.params.page}
                        pageSize={this.params.pageSize}
                        sortColumn={__sync(this.params.sortColumn)}
                        sortDirection={__sync(this.params.sortDirection)}
                        items={items}
                        selectedItems={__sync(this.selectedRows)}
                        totalItems={totalItems}
                        //
                    >
                        <ColumnName />
                        <ColumnDescription />
                        <ColumnUserCount />
                        <ColumnControl />
                    </Table>
                </div>
            ),
        }

        return (
            <MainListLayout>
                {/* <BErrorState /> */}
                {/* <LifeCycle name={'parent'}>
                    <LifeCycle name={'child0'}>
                        <LifeCycle name={'child1'} />
                    </LifeCycle>
                </LifeCycle> */}
                <Header />
                <Divider />
                <Filters />
                <EntityQuery queryName='RolesQuery' params={this.params} scopedSlots={querySlots} />
            </MainListLayout>
        )
    },
})

// const LifeCycle = defineComponent({
//     props: {
//         name: {
//             type: String,
//             required: true,
//         },
//     },
//     data() {
//         console.log(`${this.name}: 2.data`)

//         const params = {
//             keyword: 'kw',
//         }

//         return {
//             params,
//         }
//     },
//     watch: {
//         params: {
//             deep: true,
//             immediate: true,
//             handler() {
//                 console.log(`${this.name}: 3.watch:params`)
//             },
//         },
//     },
//     beforeCreate() {
//         console.log(`1.beforeCreate`)
//     },
//     created() {
//         console.log(`${this.name}: 4.created`)
//     },
//     beforeMount() {
//         console.log(`${this.name}: 5.beforeMount`)
//     },
//     mounted() {
//         console.log(`${this.name}: 6.mounted`)
//     },
//     render() {
//         const defaultSlot = this.$scopedSlots.default
//         return (
//             <div>
//                 <h1>LifeCycle Header: {this.name}</h1>
//                 {defaultSlot && defaultSlot()}
//                 <h2>Footer: {this.name}</h2>
//             </div>
//         )
//     },
// })
