import { defineComponent } from '@vue/composition-api'
import client from '@/libs/api-client'

export default defineComponent({
    props: {
        queryName: {
            type: String,
            required: true,
        },
        params: {
            type: [Object],
            required: false,
            default: () => {},
        },
        // page: {
        //     type: Number,
        //     default: 1,
        // },
        // pageSize: {
        //     type: Number,
        //     default: 10,
        // },
        // sortColumn: {
        //     type: String,
        //     default: 'Name',
        // },
        // sortDirection: {
        //     type: String,
        //     default: 'asc',
        //     validator: (value) => ['asc', 'desc'].includes(value),
        // },
    },
    data() {
        return {
            seq: 0,
            firstLoaded: false,
            loading: false,
            delayLoading: false,
            error: null,
            items: [],
            totalItems: 0,
        }
    },
    computed: {
        page() {
            const { page } = this.params
            return page && typeof page === 'number' ? page : 1
        },
        pageSize() {
            const { pageSize } = this.params
            return pageSize && typeof pageSize === 'number' ? pageSize : 10
        },
        sortColumn() {
            const { sortColumn } = this.params
            return sortColumn && typeof sortColumn === 'string' ? sortColumn : 'Name'
        },
        sortDirection() {
            const { sortDirection } = this.params
            return sortDirection && typeof sortDirection === 'string' ? sortDirection : 'asc'
        },
        customParams() {
            // Extract only custom parameters
            //
            const { page, pageSize, sortColumn, sortDirection, ...customParams } = this.params
            return customParams || {}
        },

        empty() {
            return !Array.isArray(this.items) || this.items.length === 0
        },
        beginItem() {
            return (this.page - 1) * this.pageSize + 1
        },
        endItem() {
            return this.beginItem - 1 + this.items.length
        },
    },
    watch: {
        loading(newVal) {
            // Reset delayLoading if not loading
            //
            if (!newVal) {
                this.delayLoading = false
                return
            }

            // Start delay 200ms if loading
            //
            if (newVal) setTimeout(() => (this.delayLoading = true), 200)
        },
        params: {
            handler(newVal, oldVal) {
                // console.log(newVal, oldVal)
                // console.log(Object.is(newVal, oldVal))
                this.$nextTick(() => {
                    this.load()
                    // console.log('[EntityQuery] params!')
                })
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        async load() {
            if (this.loading) return

            // console.log('[EntityQuery] load')
            // console.log(this.params)

            try {
                this.error = null
                this.loading = true
                let data = await client({
                    url: `/json/reply/${this.queryName}`,
                    params: {
                        ...this.customParams,
                        sequence: this.seq++,
                        startRow: (this.page - 1) * this.pageSize,
                        pageSize: this.pageSize,
                        sortColumn: this.sortColumn,
                        sortDirection: this.sortDirection == 'asc' ? 0 : 1,
                        jsconfig: 'eccn', // EmitCamelCaseNames
                    },
                }).then((resp) => resp.data)

                this.items = data.rows
                this.totalItems = data.totalRows
                // console.log(this.items, this.totalItems)
            } catch (error) {
                this.error = error
            } finally {
                this.loading = false
                this.firstLoaded = true
            }
        },
    },
    created() {
        // console.log('[EntityQuery] created')
        // console.log(this.params)
        // this.$watch(
        //     (vm) =>
        //         // [
        //         vm.params,
        //     //
        //     // vm.page,
        //     // vm.pageSize,
        //     // vm.sortColumn,
        //     // vm.sortDirection,
        //     // ],
        //     (newVal, oldVal) => {
        //         console.log(newVal, oldVal)
        //         console.log(Object.is(newVal, oldVal))
        //         this.$nextTick(() => {
        //             this.load()
        //             // console.log('[EntityQuery] params!')
        //         })
        //     },
        //     {
        //         deep: true,
        //         immediate: true,
        //     }
        // )
    },
    mounted() {
        // console.log('[EntityQuery] mounted')
        // console.log(this.params)
        // this.$watch(
        //     (vm) => [vm.page, vm.pageSize, vm.sortColumn, vm.sortDirection],
        //     () => {
        //         this.$nextTick(() => {
        //             console.log('[EntityQuery] common!')
        //         })
        //     },
        //     {
        //         deep: true,
        //         // immediate: true,
        //     }
        // )
    },
    render() {
        const Controls = () => {
            // console.log('Controls')

            if (this.$scopedSlots.controls)
                return this.$scopedSlots.controls({
                    load: this.load,
                })

            return <div class='controls'></div>
        }

        const Query = () => {
            // console.log('Query')
            // console.log('1')
            if (!this.firstLoaded || (this.delayLoading && this.$scopedSlots.loading))
                return this.$scopedSlots.loading()
            // console.log('2')
            if (this.error && this.$scopedSlots.error) return this.$scopedSlots.error({ error: this.error })
            // console.log('3')
            if (this.firstLoaded && this.empty && this.$scopedSlots.empty) return this.$scopedSlots.empty()
            // console.log('4')

            // if (this.$scopedSlots.combined)
            //     return this.$scopedSlots.combined({
            //         loading: this.loading,
            //         delayLoading: this.delayLoading,
            //         error: this.error,
            //         items: this.items,
            //         totalItems: this.totalItems,
            //     })

            if (this.$scopedSlots.default)
                return this.$scopedSlots.default({
                    loading: this.delayLoading,
                    error: this.error,
                    items: this.items,
                    beginItem: this.beginItem,
                    endItem: this.endItem,
                    totalItems: this.totalItems,
                })

            // console.log('5')
            return <div></div>
        }

        return (
            <div class='entity-query'>
                <Controls />
                <Query />
            </div>
        )
    },
})
